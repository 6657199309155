@media (max-width:1279px) {
  .button_Appointment button {
    font-size: 30px;
  }
}

@media(max-width:1535px) {
  .supports_sd .br {
    display: none;
  }
}

@media (max-width:1024px) {
  .button_Appointment button {
    font-size: 26px;
    list-style: 25px;
  }
}

@media (max-width:767px) {
  .br {
    display: none;
   
  }
  .text-64 {
    font-size: 40px;
    line-height: 40px;
  }

  .text-22 {
    font-size: 16px;
    line-height: 20px;
  }

  .margin-my {
    margin: 40px 0px;
  }

  .min_home_section {
    padding: 0px 0px;
    gap: 50px;
  }

  .text-30 {
    font-size: 25px;
    list-style: 25px;
  }

  .text-20 {
    font-size: 15px;
    list-style: 15px;
  }

  .text-48 {
    font-size: 30px;
    line-height: 30px;
  }

  .text-25 {
    font-size: 18px;
    list-style: 18px;
  }

  .button_Appointment button {
    font-size: 25px;
    list-style: 25px;
    width: fit-content;
  }

  .button_Appointment {
    text-align: center;
  }

  .call_icon img {
    width: 40px;
  }

  header img {
    width: 200px !important;
    height: auto !important;
  }

  header .absolute {
    margin-top: 20px;
  }
}